body {
  background-color: #1a0e02;
  color: antiquewhite;
}

h1 {
  font-size: 1.3rem;
  margin: 10%;
  text-decoration: underline;
}

a {
  color: #e72f6d;
  text-decoration: none;
}

a:hover {
  color: #ae1ee1;
}

.bold {
  font-weight: 600;
  text-transform: uppercase;
}

.App {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;

  header {
    position: relative;
    width: calc(100% - 2rem);
    text-align: center;
    margin: 1rem;

    > div {
      font-size: 1.1rem;
      text-align: center;
      margin: 1rem;
    }

    > img {
      height: 10vh;
    }
  }

  footer {
    width: 360px;
    position: relative;
    margin: 0 auto;
    padding: 10px 4px;
    margin-top: 10px;
  }

  .main {
  }

  .loader {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
    background: rgba(70, 45, 25, 0);
    text-align: center;
    font-size: xx-large;
    font-weight: 600;
  }

  .credits {
    position: fixed;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-size: small;
    bottom: 5px;
  }
}

.video-container {
  padding-top: 48.24%;
  height: 0px;
  position: relative;
  margin-right: 4rem;
  margin-left: 0;
}

#hootenany-video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid antiquewhite;
}

.caption {
  font-size: small;
  margin-top: 0.2rem;
}
